/* :root {
  --color-bg-dark: #121212;
  --color-text-light: #fff;
  --color-text-muted: #B8B8B8;
  --color-green: green;
  --color-red: red;
  --color-light-grey: #1d1a1a;
  --color-bg-gradient: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  --color-bg-dark-overlay: rgba(0, 0, 0, 0.9);
  --color-social-icon-bg: rgba(217, 217, 217, 0.1);
  --color-social-icon-border: rgba(255, 255, 255, 0.5);
  --color-input-bg: rgba(255, 255, 255, 0.1);
  --color-input-border: rgba(255, 255, 255, 0.5);
  --color-input-bg-focus: rgba(255, 255, 255, 1);
  --color-input-text-focus: #121212;
  --color-placeholder: #fff;
  --color-placeholder-focus: #121212;
  --color-btn-text: #000;
  --color-btn-bg: #fff;
  --color-btn-bg-hover: #fffafa;
  --color-footer-text: #B8B8B8;
  --color-banner-text-shadow: rgba(0, 0, 0, 0.6);
}  */

:root {
  --color-bg-dark: #121212;
  --color-text-light: #fff;
  --color-link-light: #007fcf;
  --color-link-light-hover: #0076bf;
  --color-text-muted: #B8B8B8;
  --color-green: green;
  --color-red: red;
  --color-blue: blue;
  --color-light-grey: #1d1a1a;
  --color-bg-gradient: linear-gradient(90.21deg, #3a569d -5.91%, #006eff 111.58%);
  --color-bg-gradient-3: linear-gradient(90.21deg, rgb(65, 67, 68) -5.91%, #797979 111.58%);
  --color-bg-gradient-2: linear-gradient(90.21deg, #222222 -5.91%, #797979 111.58%);
  --color-bg-gradient-hover: linear-gradient(90.21deg, #386cc1 -5.91%, #007bff 111.58%);
  --color-bg-dark-overlay: rgba(0, 0, 0, 0.9);
  --color-social-icon-bg: rgba(217, 217, 217, 0.1);
  --color-social-icon-border: rgba(255, 255, 255, 0.5);
  --color-input-bg: rgba(255, 255, 255, 0.1);
  --color-input-border: rgba(255, 255, 255, 0.5);
  --color-input-bg-focus: rgba(255, 255, 255, 1);
  --color-input-text-focus: #121212;
  --color-placeholder: #fff;
  --color-placeholder-focus: #121212;
  --color-btn-text: #000;
  --color-btn-bg: #fff;
  --color-btn-bg-hover: #fffafa;
  --color-footer-text: #B8B8B8;
  --color-banner-text-shadow: rgba(0, 0, 0, 0.6);
  --banner-bg-image: url('./assets/img/banner-bg-1.jpg');
  --footer-bg-image: url('./assets/img/footer-bg-01.jpg');
  --body-bg-image-01-left: url('./assets/img/color-sharp-01.png');
  --body-bg-image-01-right: url('./assets/img/color-sharp2-01.png');
} 

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Vintage';
  src: url('./assets/font/Vintage-Signature.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Light';
  src: url('./assets/font/Gotham-Narrow-Light.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Ultra';
  src: url('./assets/font/Gotham-Narrow-Ultra.otf');
  font-weight: normal;
  font-style: normal;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: var(--color-bg-dark) !important;
  color: var(--color-text-light) !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a {
  color: var(--color-link-light);
  text-decoration: none !important;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--color-link-light-hover);
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: var(--color-green);
}

p.danger {
  color: var(--color-red);
}

/************ Navbar Css ************/
nav.navbar {
  z-index: 1050; /* Make sure the navbar is above other content */
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: var(--color-bg-dark);
}
nav.navbar a.navbar-brand {
  /* width: 9%; */
  width: auto;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: var(--color-text-light) !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: var(--color-social-icon-bg);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid var(--color-social-icon-border);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: var(--color-text-light);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: var(--color-text-light);
  border: 1px solid var(--color-text-light);
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: var(--color-text-light);
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: var(--color-bg-dark);
}
.navbar-text button:hover::before {
  width: 100%;
  height: 100%;
  position: absolute;
}
/* Only apply the semi-transparent background when the navbar is toggled */
nav.navbar.toggled {
  background-color: var(--color-bg-dark-overlay);
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid var(--color-text-light);
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid var(--color-text-light);
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: var(--color-text-light);
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: var(--color-text-light);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: var(--color-text-light);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.title-Gotham-Ultra {
  font-family: 'Gotham-Ultra', cursive;
  font-size: 34px;
  color: var(--color-text-light);
  text-shadow: 1px 1px 2px var(--color-banner-text-shadow);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-Gotham-Light {
  font-family: 'Gotham-Light', cursive;
  font-size: 32px;
  color: var(--color-text-light);
  text-shadow: 1px 1px 2px var(--color-banner-text-shadow);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  nav.navbar a.navbar-brand {
    font-size: 24px; 
    width: auto; 
  }
  
  nav.navbar .navbar-nav .nav-link.navbar-link {
    padding: 0 15px; 
    font-size: 16px; 
  }
  
  .navbar-text button {
    font-size: 14px; 
    padding: 10px 20px;
  }
  
  .title-Gotham-Ultra {
    font-size: 36px; 
  }
  
  .title-Gotham-Light {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .title-Gotham-Ultra {
    font-size: 34px; 
  }
  
  .title-Gotham-Light {
    font-size: 16px; 
  }
  
  nav.navbar .navbar-toggler-icon {
    /* width: 20px;  */
    /* height: 15px; */
  }
  
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 14px; 
  }
  
  .navbar-text button {
    font-size: 12px;
    padding: 8px 16px; 
  }
}

@media (max-width: 576px) {
  .title-Gotham-Ultra {
    font-size: 28px; 
  }
  
  .title-Gotham-Light {
    font-size: 14px; 
  }
  
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 12px;
    padding: 0 10px; 
  }
  
  .navbar-text button {
    font-size: 10px; 
    padding: 6px 12px; 
  }
}

/************ Banner Css ************/
.banner {
  padding-bottom: 50px;
  overflow: hidden;
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: var(--banner-bg-image);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner, .slick-slider, .slick-list, .slick-track {
  overflow: visible;
  z-index: auto; /* Reset z-index to ensure visibility */
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: var(--color-bg-gradient);
  border: 1px solid var(--color-social-icon-border);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: var(--var(--color-text-light));
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: var(--color-text-light);
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 10s linear infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
.slick-dots {
  bottom: -50px !important;
  height: 1rem !important;
  
}
.slick-dots li {
  color: white;
}
.slick-dots li button:before {
  font-size: 16px !important;
  color: white !important; 
}

/* Responsive adjustments */
/* Extra Large Devices (Large Desktops) */
@media (max-width: 1400px) {
  .banner h1 {
    font-size: 56px;
  }
}
/* Large Devices (Laptops and Desktops) */
@media (max-width: 1200px) {
   .banner h1 {
    font-size: 52px;
  }
}
/* Medium Devices (Small Laptops) */
@media (max-width: 992px) {
  .banner h1 {
    font-size: 46px;
  }
}
/* Small Devices (Tablets) */
@media (max-width: 768px) {
  .banner h1 {
    font-size: 42px;
  }
}
/* Extra Small Devices (Phones) */
@media (max-width: 576px) {
  .banner h1 {
    font-size: 36px;
  }
}


/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
  font-size: 45px;
  font-weight: 700;
}
.skill p {
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

/* Skills Intro */
.skills-intro {
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 1.6;
  color: var(--color-text-light);
  padding: 10px;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}
.skill-image {
  width: 70px;
  height: 90px;
  object-fit: contain;
}

/************ Projects Css ************/
.project {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--color-bg-dark);
  color: var(--color-text-light);
}

.project h2 {
  font-size: 20px; /* Adjusted for small screens */
  text-align: center;
}

.project p {
  font-size: 14px;
  line-height: 1.4;
  margin: 0 10px;
}

.project-image {
  width: 90%;
  margin: 10px auto;
}

.navbar-text button, .project-git-link a {
  font-size: 14px;
  padding: 12px 24px;
}

/* .sidebar {
  display: none;
  background-color: #333; 
  color: var(--color-text-light); 
  padding: 15px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2); 
} */

/* .sidebar {
  flex: 0 0 250px;
  background: #2b2b2b;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky; 
  top: 75px; 
  height: calc(100vh - 75px);
  overflow-y: auto;
  transition: top 0.3s ease-in-out;
}

.sidebar.sticky {
  top: 0;
}

.sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
} */

@media (min-width: 768px) {
  .project {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .project h2 {
    font-size: 24px;
  }

  .project p {
    font-size: 16px;
    margin: 0 15px;
  }

  .project-image {
    width: 100%;
  }

  /* .sidebar {
    display: block;
    flex: 0.3;
    padding: 10px;
  } */

  .navbar-text button, .project-git-link a {
    font-size: 16px;
    padding: 15px 30px;
  }

  .project-description {
    font-size: 18px; /* Slightly larger font size */
  }
}

@media (min-width: 992px) {
  .project {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .project h2 {
    font-size: 28px;
  }

  .project p {
    font-size: 18px;
    margin: 0 20px;
  }

  .sidebar {
    padding: 15px;
  }
}

@media (min-width: 1200px) {

  .project {
    padding-top: 5rem;
    padding-bottom: 5rem;

    /* background-image: var(--body-bg-image-01-left); */
  /* background-image: var(--body-bg-image-01-right); */
  /* background-image: var(--banner-bg-image); */
  
    /* top: 28%; */
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 40%; */
  z-index: -4;

  background-repeat: no-repeat;
  background-size: cover; 
  /* background: var(--color-bg-gradient-2); */
  }

  .project-detailszzz {
    padding-top: 5rem;
    /* padding-bottom: 5rem; */

    background-image: var(--body-bg-image-01-left);
  /* background-image: var(--body-bg-image-01-right); */
  /* background-image: var(--banner-bg-image); */
  
    /* top: 28%; */
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 40%; */
  z-index: -4;

  background-repeat: no-repeat;
  background-size: cover; 
  /* background: var(--color-bg-gradient-2); */
  }

  .project-details {
  padding-top: 5rem;
  /* background-image: var(--body-bg-image-01-left); */
  /* background-image: var(--body-bg-image-01-right); */
  /* background-repeat: no-repeat; */
  /* Adjust the size of the background image */
  /* background-size: cover;  */
  /* Center the background image */
  /* background-position: center;  */
  /* Required for the ::after pseudo-element */
  position: relative; 
  /* Ensure this element is above the pseudo-element */
  z-index: 1; 
}

.project-details::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--body-bg-image-01-left);
  background-repeat: no-repeat;
  background-size: cover; /* Adjust the size of the background image */
  background-position: center; /* Center the background image */
  opacity: 0.5; /* Adjust the opacity */
  z-index: -1; /* Ensure the pseudo-element is behind the main content */
}

  .project h2 {
    font-size: 32px;
  }

  .project p {
    font-size: 20px;
    margin: 0 25px;
  }

  .navbar-text button, .project-git-link a {
    font-size: 18px;
    padding: 18px 36px;
  }
}

.project .nav.nav-pills {
  width: 90%;
  margin: 2rem auto;
  border-radius: 20px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 25%;
  height: 5rem;
}
.project .nav.nav-pills .nav-link {
  display: flex; /* Use Flexbox */
  align-items: center; /* Vertically center the text */
  justify-content: center; /* Horizontally center the text */
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: var(--color-text-light);
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
  height: 5rem;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: var(--color-bg-gradient);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  /* border: 1px solid rgba(255, 255, 255, 1); */
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid var(--color-social-icon-border);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid var(--color-social-icon-border);
  border-bottom: 1px solid var(--color-social-icon-border);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid var(--color-social-icon-border);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 250px;
}
.proj-imgbx img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.proj-imgbx::before {
  content: "";
  background: var(--color-bg-gradient);
  opacity: 0.89;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  min-height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
  color: var(--color-text-light);
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  /* font-style: italic; */
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
/* .background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
} */
.project .background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Contact Css ************/
/* .banner {
  padding-bottom: 50px;
  overflow: hidden;
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: var(--banner-bg-image);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
} */

.contact {
  /* background-image: var(--body-bg-image-01-left); */
  /* background-image: var(--body-bg-image-01-right); */
  /* background-image: var(--banner-bg-image); */
  
    /* top: 28%; */
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 40%; */
  /* z-index: -4; */

  /* background-repeat: no-repeat; */
  /* background-size: cover;  */
  /* background: var(--color-bg-gradient-2); */
  
  padding: 60px 0 60px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: var(--color-input-bg);
  border: 1px solid var(--color-input-border);
  border-radius: 20px;
  color: var(--color-text-light);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: var(--color-input-bg-focus);
  color: var(--color-input-text-focus);
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-placeholder);
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: var(--color-placeholder-focus);
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: var(--color-btn-text);
  background-color: var(--color-btn-bg);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 16px;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: var(--color-btn-text);
  background: var(--color-btn-bg-hover);
}

/************ Footer Css ************/
.footer {
  padding: 0 0 150px 0;
  background-image: var(--footer-bg-image);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.new-email-bx {
  background: var(--color-btn-bg);
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: var(--color-bg-gradient);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: var(--color-btn-bg);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: var(--color-input-text-focus);
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: var(--color-bg-gradient);
  padding: 20px 65px;
  color: var(--color-text-light);
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 40%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-footer-text);
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/************ Responsive Newsletter Css ************/
.newsletter-container {
  padding-top: 80px;
  padding-bottom: 30px;
}

.newsletter-bx {
  background: var(--color-btn-bg);
  border-radius: 40px;
  color: var(--color-input-text-focus);
  padding: 40px 20px;
  margin-bottom: 20px;
  text-align: center;
}

.newsletter-bx h3 {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 24px;
}

.new-email-bx {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-right: 25px;
  padding-left: 25px;
}

.new-email-bx input {
  flex: 1 1 200px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.new-email-bx button {
  padding: 10px 20px;
  background: var(--color-bg-gradient);
  color: var(--color-text-light);
  cursor: pointer;
  border: none;
  margin-bottom: 10px;
}

.new-email-bx button:hover {
  /* background: linear-gradient(90.21deg, #4A2FBD -5.91%, #AA367C 111.58%); */
  background: var(--color-bg-gradient-hover);
}

/************ Additional Styles ************/
@media (min-width: 768px) {
  .newsletter-bx {
    padding: 60px 40px;
  }
  .newsletter-bx h3 {
    font-size: 28px;
  }
  .new-email-bx input {
    margin-right: 20px;
  }
}

@media (min-width: 992px) {
  .newsletter-bx {
    padding: 80px 60px;
  }
  .newsletter-bx h3 {
    font-size: 32px;
  }
}

/************ Project Details Specific Styles ************/
.project-details-container {
  padding: 2rem;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%; 
}

.project-title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.project-image {
  width: 100%;
  margin-bottom: 20px;
}

.project-intro {
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  color: var(--color-text-light);
  padding: 20px;
}

.project-meta {
  margin-bottom: 20px;
  margin-top: 20px;
}

.project-meta p {
  margin: 10px 0px 0;
  font-size: 1rem;
}

.project-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.project-content {
  display: flex;
  gap: 20px;
  margin-bottom: 5rem;
  /* Ensure sidebar stays at the top */
  /* align-items: flex-start;  */
  flex-wrap: wrap;  /* Adjust for smaller screens updated on Jun 15*/
   height: 100%;
}

.sidebar {
  /* flex: 0 0 250px; */
  flex: 1 1 auto;
  background: #2b2b2b;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; 
  top: initial; 
  height: auto; 
  /* height: 100%; */
  overflow-y: auto;
  transition: top 1.5s ease-in-out, height 1.5s ease-in-out;
  z-index: 1;
  min-width: 250px;
  max-width: 250px;
  margin-right: 20px; 
  /* padding-bottom: 50px; */
}

.sidebar.sticky {
  position: fixed; 
  top: 75px;
  height: 100%; 
  z-index: 2; 
}

.sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.chapter-list {
  list-style: none;
  padding: 0;
}

.chapter-list li {
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
  color: #2b2b2b;
  background-color: #e0e0e0;
}

.chapter-list li:hover,
.chapter-list li.active {
  background-color: #ffffff;
}

.chapter-content {
  flex: 1;
  background-color: white;
  color: #1b1b1b;
  padding: 1.2rem;
  border-radius: 8px;
  overflow-y: auto; 
  z-index: 0; /* Ensure the chapter content is below the sidebar */
  /* max-height: calc(100vh - 75px); */
  /* Adjust to ensure it doesn't overlap with the sidebar */
  /* max-width: calc(100% - 250px);  */
  
  margin-left: 20px; 
  max-width: calc(100% - 270px); /* Ensure it adjusts correctly with the sidebar */
  
}
.chapter-content-details {
  width: 100%;
}
.chapter-image {
  width: 80%;
  height: auto;
  display: block;
  margin-left: auto; 
  margin-right: auto; 
  padding-bottom: 20px;
}
.chapter-images {
  margin-top: 20px;
  margin-bottom: 20px;
}

.chapter-images h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

/* .chapter-images img {
  max-height: 38rem !important;
  width: auto;
  height: auto;
  object-fit: contain;
} */
.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-gallery img {
  max-width: 100%;
  height: auto;
  flex: 1 1 100%;
}

.image-gallery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 80%;
}

.image-gallery .image-gallery-left-nav,
.image-gallery .image-gallery-right-nav {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery .image-gallery-left-nav {
  left: -80px; 
}

.image-gallery .image-gallery-right-nav {
  right: -80px; 
}

.chapter-videos h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.chapter-videos ul {
  list-style-type: none;
  padding: 0;
}

.chapter-videos li {
  margin-bottom: 5px;
}

.chapter-files {
  margin-bottom: 20px;
}

.chapter-files h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.chapter-files ul {
  list-style-type: none;
  padding: 0;
}

.chapter-files li {
  margin-bottom: 5px;
}

.chapter-support-urls {
  margin-bottom: 20px;
  margin-top: 20px;
}

.chapter-support-urls h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.chapter-support-urls ul {
  list-style-type: none;
  padding: 0;
}

.chapter-support-urls li {
  margin-bottom: 5px;
}

.project-git-link {
  margin-top: 20px;
}

/* .project-git-link a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.project-git-link a:hover {
  background-color: #555;
} */
 .project-git-link a {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--color-light-grey);
  color: var(--color-text-light);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.project-git-link a:hover {
  background-color: var(--color-bg-gradient-hover);
  color: var(--color-btn-text);
}

.share-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

/* Media query for responsive design */
@media (max-width: 1200px) {
   .image-gallery .image-gallery-left-nav {
    left: -3rem; 
  }

  .image-gallery .image-gallery-right-nav {
    right: -3rem; 
  }

  .image-gallery-image{
    max-width: 40rem !important;
  }
}
/* Medium Devices (Small Laptops) */
@media (max-width: 992px) {
  .image-gallery .image-gallery-left-nav {
    left: 2rem; 
  }

  .image-gallery .image-gallery-right-nav {
    right: 2rem; 
  }

  .image-gallery-image{
    max-width: 30rem !important;
  }
}
@media (max-width: 768px) {
  .project-content {
    flex-direction: column;
  }

  .sidebar {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .chapter-content {
    margin-left: 0;
    max-width: 100%;
  }  

  .image-gallery .image-gallery-left-nav {
    left: 1rem; 
  }

  .image-gallery .image-gallery-right-nav {
    right: 1rem; 
  }

  .image-gallery-image{
    max-width: 25rem !important;
  }
}

@media (max-width: 576px) {
  .image-gallery .image-gallery-left-nav {
    left: 5.5rem; 
  }

  .image-gallery .image-gallery-right-nav {
    right: 5.5rem; 
  }

  .image-gallery-image{
    max-width: 20rem !important;
  }
}


/* Loading Spinner Overlay */
.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.99);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-spinner {
  width: 300px;
  height: 170px;
}

/* To Top button */
.to-top {
  transition: transform 0.2s, bottom 0.2s;
}

.to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  z-index: 1000;
}

.to-top.visible {
  display: block;
}

.to-top:hover{
  color: var(--color-text-light);
  text-decoration: none !important;
  transition: color 0.3s ease;
}